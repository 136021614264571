<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Business from '@/services/Business';
    import modalAddCompany from '@/components/modals/customers/modalAddCompany';
    import ModalHighlightedBusinessWallStatus from '@/components/modals/business/modalHighlightedBusinessWallStatus';
    import  ModalEditOrderBusinessWall from '@/components/modals/business/modalEditOrderBusinessWall';
    import  ModalAddCompanyToBusinessWall from '@/components/modals/business/modalAddCompanyToBusinessWall'
    import Swal from "sweetalert2";

    export default {
        components: { Layout, PageHeader, ModalHighlightedBusinessWallStatus, ModalEditOrderBusinessWall, ModalAddCompanyToBusinessWall,  /*VueBootstrapTypeahead ,*/ modalAddCompany },
        page: {
            title: "Business Wall",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                isAuthorized: false,
                error: null,
                filterData: false,
                showMapData: false,
                showMap: false,
                tableData: [],
                title: "Business Wall",
                items: [
                    {
                        text: "Business",
                        href: "/business/companies",
                    },
                    {
                        text: "Business Wall",
                        active: true,
                    },
                ],
                ticketing_client_types:[],
                userStatuses: [],
                filterInput: {
                    name: '',
                    gender: [],
                    company_id: '',
                    userStatus: [],
                },
                companyData: [],
                selectedBusinessWallCompanyDetails:null,
                showModal: false,
                countries: [],
                isBusy: false,
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "created_on",
                sortDesc: true,
                fields: [
                    {
                        key: "company_id",
                        label: "Company Id",
                        sortable: true,
                    },
                    {
                        key: "name",
                        label: "Company Name",
                        sortable: true,
                    },
                    {
                        key: "description",
                        label: "Description",
                        sortable: true,
                    },
                    {
                        key: "website",
                        label: "Website",
                        sortable: true,
                    },
                    {
                        key: "is_highlighted",
                        label: "Is Highlighted",
                        sortable: true,
                    },
                    {
                        key: "order",
                        label: "Order",
                        sortable: true,
                    },
                    {
                        key: "background_color",
                        label: "Background Color",
                        sortable: true,
                    },
                    {
                        key: "company_image",
                        label: "Company Image",
                        sortable: true,
                    },
                    {
                        key: "logo_url",
                        label: "Logo Image",
                        sortable: true,
                    },
                    "action"
                ],
            };
        },

        methods: {

            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            modalAddCompanyToBusinessWall(){
                this.$bvModal.show("add_company_to_business_wall");
            },
            openEditHighlightModal(businessWallItem){
                this.selectedBusinessWallCompanyDetails = businessWallItem;
                this.$bvModal.show('status_business_wall_is_highlighted')
            },
            openEditOrderModal(businessWallItem){
                this.selectedBusinessWallCompanyDetails = businessWallItem;
                this.$bvModal.show('edit_order_business_wall')
            },
            async getBusinessWall(){
                try {

                    this.toggleBusy();
                    // var filters = this.getFilters()
                    const response = await Business.getBusinessWall();

                    this.tableData = response.data.data
                    this.totalRows = this.tableData.length
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRows = 0;
                } finally {
                    this.toggleBusy();
                }
            },
            removeCompanyFromBusinessWall(id){
                Swal.fire({
                    title: "Remove company from Business Wall?",
                    text: "This company will be removed!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Remove!",
                }).then((result) => {
                    if (result.value) {
                        try {
                            Business.deleteCompanyFromBusinessWall(id)
                                .then((response) => {
                                    const res = response.data ? response.data : false;
                                    const error = response.data.error ? response.data.error : 'Failed';
                                    if(res){
                                        this.getBusinessWall()
                                        Swal.fire("Remove company", "Company is successfully removed!", "success");
                                    }else{
                                        Swal.fire("Fail!", error, "warning");
                                    }
                                })
                        }catch(error){
                            this.error = error.response.data.error ? error.response.data.error : "";
                        }
                    }
                });
            },
            // getFilters(){
            //     var filter_string = '?limit=10000';
            //     filter_string += (this.filterInput.name) ? '&name='+this.filterInput.name : "";
            //     filter_string += (this.filterInput.company_id) ? '&company_id='+this.filterInput.company_id : "";
            //     return filter_string = filter_string.substring(1);
            // },


        },
        mounted() {
            this.getBusinessWall();
        },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-12">
                <div class="card" >
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col d-inline-flex align-items-center" >
                                <h4 class="card-title m-0">Total Companies: {{totalRows}}</h4>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="mb-3">
                                <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light me-3"
                                   @click="modalAddCompanyToBusinessWall">
                                    <i class="mdi mdi-plus me-2"></i>
                                    Add Company To Business Wall
                                </a>
                            </div>
                        </div>

                        <div class="row mt-4" v-show="filterData">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-end"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ms-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="tableData"
                                    :busy="isBusy"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                                    show-empty
                                    empty-text="No Data Found"
                            >

                                <template v-slot:cell(company_id)="data">
                                    {{ data.item.company_id }}
                                </template>
                                <template v-slot:cell(description)="data">
                                    {{ data.item.description }}
                                </template>
                                <template v-slot:cell(website)="data">
                                    <a :href="data.item.website" class="nav-link">
                                        <span>{{data.item.website}}</span>
                                    </a>
                                </template>
                                <template  v-slot:cell(is_highlighted)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <span v-if="data.item.is_highlighted === 1">Yes</span>
                                            <span v-else>No</span>
                                            <a

                                                    href="javascript:void(0);"
                                                    @click="openEditHighlightModal(data.item)"
                                                    class="px-2 text-primary"
                                                    v-b-tooltip.hover
                                                    title="Edit Is Highlighted"
                                            >
                                                <i class="uil uil-pen font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>

                                </template>
                                <template  v-slot:cell(order)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item  d-flex align-items-center">
                                            <span>{{data.item.order}}</span>
                                            <a

                                                    href="javascript:void(0);"
                                                    @click="openEditOrderModal(data.item)"
                                                    class="px-2 text-primary"
                                                    v-b-tooltip.hover
                                                    title="Edit Order"
                                            >
                                                <i class="uil uil-pen font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>

                                </template>
                                <template  v-slot:cell(background_color)="data">
                                    <!--              <b-button size="sm" @click="openModal(row.item)">View</b-button>-->
                                    <!--              <b-button size="sm" variant="warning" @click="openEditModal(row.item)">Edit</b-button>-->
                                    <ul class="list-inline mb-0">
                                        <span :style="{ background: data.item.background_color, borderRadius: '5px', padding:'5px' }">Color</span>
                                    </ul>

                                </template>
                                <template v-slot:cell(company_image)="data">
                                    <img :src="data.item.company_image" style="width: 80px;" />
                                </template>
                                <template v-slot:cell(logo_url)="data">
                                    <img :src="data.item.logo_url" style="width: 80px;" />
                                </template>
                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover title="Remove Company" @click="removeCompanyFromBusinessWall(data.item.company_id)">
                                                <i class="uil uil-trash-alt font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>
                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row" v-show="filterData">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modalAddCompany @onRefresh="getBusinessWall()"></modalAddCompany>
        <ModalHighlightedBusinessWallStatus
                @onRefresh="getBusinessWall()"
                :businessWallCompanyDetails="selectedBusinessWallCompanyDetails"
        ></ModalHighlightedBusinessWallStatus>
        <ModalEditOrderBusinessWall
          @onRefresh="getBusinessWall()"
          :businessWallCompanyDetails="selectedBusinessWallCompanyDetails"
        ></ModalEditOrderBusinessWall>
        <ModalAddCompanyToBusinessWall @onRefresh="getBusinessWall"
        ></ModalAddCompanyToBusinessWall>
    </Layout>
</template>
