<script>
import BusienssService from "@/services/Business";

export default {
  props: ['businessWallCompanyDetails'],
  data() {
    return {
      editableBusinessWall: {},
    };
  },
  watch: {
    businessWallCompanyDetails: {
      handler(newVal) {
        this.editableBusinessWall = { ...newVal };
      },
      immediate: true,
    },
  },
  methods: {
    async saveChanges() {
      const payload = {
        order: this.editableBusinessWall.order,
      };
      console.log(payload,'payload')
      console.log(this.editableBusinessWall,'editable buss')

      try {
        const response = await BusienssService.updateBusinessWall(this.businessWallCompanyDetails.company_id, payload);
        console.log(response,'res in modal')
        if (response.data.success) {
          // Emit event for parent to update after save
          this.$emit('update-success', this.editableBusinessWall);
          this.$emit('onRefresh');  // Emit refreshTable only after saving
          this.$bvModal.hide('edit_order_business_wall');  // Close the modal after save
          // window.location.reload();

        } else {
          throw new Error('Failed to save changes');
        }
      } catch (error) {
        console.error("Error updating order:", error);
        alert("Error saving changes. Please try again.");
      }
    },

    closeModal() {
      // Only hide the modal, no page reload or table refresh here
      this.$bvModal.hide('edit_order_business_wall');
    },
  }

};
</script>

<template>
  <b-modal id="edit_order_business_wall" title="Edit  Order" @hidden="closeModal" size="xl" centered>
    <template v-slot:default>
      <div class="row">
        <div class="col-sm-6">
          <b-form-group label="Order">
<!--            <b-form-select v-model="editableBusinessWall.order"  :options="[-->
<!--            { value: 0, text: 'No' },-->
<!--            { value: 1, text: 'Yes' },-->
<!--    ]"  />-->
            <b-form-input type="number" v-model="editableBusinessWall.order" />
          </b-form-group>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Cancel</b-button>
      <b-button variant="primary" @click="saveChanges">Save Changes</b-button>
    </template>
  </b-modal>
</template>
