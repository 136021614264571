<script>
import BusienssService from "@/services/Business";
import Customers from "@/services/Customers";
import Switches from "vue-switches";

export default {

  data() {
    return {
      // editableBusinessWall: {}, // Local copy for editing
        companies:null,
        selectedCompany: null,
        is_highlighted: 0,
        errorMessage:''
    };
  },
    methods: {
      async getAllCompanies(){
          try {

              this.toggleBusy();

              const response = await Customers.getCompanies()
              this.companies = response.data.data
          } catch (error) {
              this.error = error.response.data.error ? error.response.data.error : "";
              this.companies = null
          } finally {
              this.toggleBusy();
          }
      },
    async saveChanges() {


        const data = {
            "company_id": this.selectedCompany,
            "is_highlighted": this.is_highlighted,
        }


      try {
        const response = await BusienssService.addCompanyToBusinessWall(data);
        if (response.status >= 200 && response.status < 300) {
          // Emit event for parent to update after save
          // this.$emit('update-success', this.editableBusinessWall);
          this.$emit('onRefresh');  // Emit refreshTable only after saving
          this.$bvModal.hide('add_company_to_business_wall');  // Close the modal after save


        } else {
            throw new Error('Failed to save changes');
        }
      } catch (error) {
          this.errorMessage = error.response.data.error
        // alert("Error saving changes. Please try again.");
      }
    },

    closeModal() {
      // Only hide the modal, no page reload or table refresh here
      this.$bvModal.hide('add_company_to_business_wall');
      this.errorMessage=''
        this.selectedCompany=null
    },


  },
  mounted() {
      this.getAllCompanies()
  }

};
</script>

<template>
  <b-modal id="add_company_to_business_wall" title="Add Company To Business Wall" @hidden="closeModal" size="xl" centered>
    <template v-slot:default>
      <div class="row">
        <div class="col-sm-6">
          <b-form-group label="Company">
              <b-form-select
                      v-model="selectedCompany"
                      :options="companies.map(company => ({ text: company.name, value: company.company_id }))"
                      placeholder="Select a company">
              </b-form-select>
          </b-form-group>
            <b-form-group label="Is Highlighted">
                <div class="form-check form-switch form-switch-lg" dir="ltr">
                    <input
                            v-model="is_highlighted"
                            type="checkbox"
                            class="form-check-input"
                    />
                </div>
            </b-form-group>
            <p class="text-danger" v-if="errorMessage">{{errorMessage}}</p>
        </div>
      </div>
    </template>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Cancel</b-button>
      <b-button variant="primary" @click="saveChanges" >Save Changes</b-button>
    </template>
  </b-modal>
</template>
